import React from 'react';
import { useEffect } from 'react';

const Navbar = () => {

    useEffect(() => {

      // Get the navigation element
        const nav = document.getElementById('bottom-nav');

        // Function to toggle sticky class based on viewport width
        function toggleStickyNav() {
            if (window.innerWidth < 900) {
                nav.classList.add('sticky');
            } else {
                nav.classList.remove('sticky');
            }
        }

        // Initial check and add event listener for resizing
        toggleStickyNav();
        window.addEventListener('resize', toggleStickyNav);


    }, []);


    return (
      <div>
        {/* TOP NAVBAR */}
         <nav id="top-nav" class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler"
                type="button" data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation">
                
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse top-nav-body" id="navbarNavAltMarkup">
                <ul class="navbar-nav ms-auto">
                    <li class="top-nav-item">
                        <div>
                            <img src="assets/TEMP-clock.png" alt="icon" width="50" height="50" aria-hidden="true" decoding="async"/>
                        </div>
                        <div>
                            <span class="top-nav-header">Hours of Operation:</span>
                            <a href="mailto:ochc@oneidacountyhistory.org" class="top-nav-link">Tuesday-Saturday 10:00am - 4:00pm
                            </a>
                        </div>
                    </li>
                    <li class="top-nav-item">
                        <div>
                            <img src="assets/TEMP-phone.png" alt="icon" width="50" height="50" aria-hidden="true" decoding="async"/>
                        </div>
                        <div>
                            <span class="top-nav-header">Call Us:</span>
                            <a href="tel:315-735-3642" class="top-nav-link">315-735-3642</a>
                        </div>
                    </li>
                    <li class="top-nav-item">
                        <div>
                            <img src="assets/TEMP-location.png" alt="icon" width="50" height="50" aria-hidden="true" decoding="async"/>
                        </div>
                        <div>
                            <span class="top-nav-header">Location</span>
                            <a href="https://www.google.com/maps/place/Oneida+County+History+Center/@43.0914788,-75.2523385,15z/data=!4m6!3m5!1s0x89d9412dc1632acf:0xcaba271eb9a1d0b0!8m2!3d43.0914788!4d-75.2523385!16s%2Fg%2F1thtql52?entry=ttu" 
                                class="top-nav-link" 
                                target="_blank">Utica, NY
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
          </nav>
        {/* BOTTOM NAVBAR */}
    {/*  */}
    <nav id="bottom-nav" className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><img id='logo_img' alt='Company Logo' className='company_logo' src='assets/TEMP-logo.png'/></a>
         <button id="bottom-nav-mobile-tel" className="navbar-toggler" aria-label='Mobile Phone'>
            <a href="tel:315-735-3642">
                <img src="assets/TEMP-phone.png" alt="icon" width="50" height="50" aria-hidden="true" decoding="async" aria-label='Mobile phone'/>
            </a>
          </button>
          <button className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <button className="dropbtn dropdown_btn" aria-label='Visit Us'>
                    <a className="nav-link" href="/visit">Visit Us</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/visit">Visit</a>
                      <a href="/exhibits">Exhibits</a>
                      <a href="/programs-and-events">Programs & Events</a>
                      <a href="/events">Calendar</a>
                      {/* <a href="/tours-and-rentals">Tours & Rentals</a> */}
                    </div>
                  </div> 
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <button className="dropbtn dropdown_btn" aria-label='About Us'>
                    <a className="nav-link" href="/about">About Us</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/about">Staff & Board</a>
                      <a href="/our-history">Our History</a>
                      <a href="/news">Latest Events & News</a>
                      <a href="/contact">Contact Us</a>
                    </div>
                  </div> 
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <button className="dropbtn dropdown_btn" aria-label='Research & Learn'>
                    <a className="nav-link" href="/research-and-learn">Research & Learn</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/research-and-learn">Research & Learn</a>
                      <a href="/visit-research-library">Visit Research Library</a>
                      <a href="/research-request">Research Services</a>
                      {/* <a href="/education-and-resources" id="bottom-nav-education" >Education & Resources</a> */}
                    </div>
                  </div> 
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <button className="dropbtn dropdown_btn" aria-label='Support Us'>
                    <a className="nav-link" href="/support-us">Support Us</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/support-us">Support Us</a>
                      <a href="/volunteer">Volunteer</a>
                      <a href="/membership">Membership</a>
                      <a href="/telethon">Telethon</a>
                    </div>
                  </div> 
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">Careers</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://oneidacountyhistory.square.site/" target="_blank">Shop</a>
                </li>
              </ul>
          </div>
      </div>
    </nav>
   

      </div>
      
    );
}

export default Navbar;